import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj35 = () => {
  return (
    <Layout>
      <SEO title="Collatz Conjecture" description="Awesome python project" />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-27">
          <div className="py-1-heading">
            <h1>Collatz Conjecture</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Collatz%20Conjecture"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              The Collatz conjecture is quite possibly the simplest unsolved
              problem in mathematics.
              <br />
              <br />
              Also known as Conjecture of Ulam or problem of Syracuse.
              <br />
              <br />
              Well, still its just a squence, but unsolved...
              <br />
              <br />
              The Sequence should be like this:
              <br />
              Take any positive integer n.
              <br /> If n is even, divide it by 2 to get n/2.
              <br /> If n is odd, multiply it by 3 and add 1 to obtain 3n+1.
              <br />
              <br />
              Try it!
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj35
